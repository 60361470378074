//=================================================
// Videos
//=================================================

import Vimeo from '@vimeo/player';
import { getReducedMotion } from './user-preferences';

function updateVideoButton(video) {
    const button = video.parentElement.querySelector('.video__play');
    const isPaused = video.paused;

    button.classList.toggle('playing', !isPaused);
    button.classList.toggle('paused', isPaused);
    button.setAttribute('aria-label', isPaused ? 'Play' : 'Pause');
}

export function playHandler(video) {
    video.play();
    updateVideoButton(video);
}

function pauseHandler(video) {
    video.pause();
    updateVideoButton(video);
}

function autoplayHandler(video) {
    if (video.hasAttribute('autoplay')) {
        // Only autoplay if reduced motion is off
        if (getReducedMotion()) {
            pauseHandler(video);
        } else {
            playHandler(video);
        }
    }
    video.removeEventListener('canplay', autoplayHandler);
}

// Resize all embedded videos (YouTube + Vimeo)
export function resizeVideos() {
    // Find all embedded Vimeo and YouTube videos
    document.querySelectorAll('iframe[src^="https://player.vimeo.com"], iframe[src^="https://www.youtube.com"], object, embed').forEach((video) => {
        // Calculate aspect ratio if it isn't set, based on initial height/width
        const wrapper = video.parentElement;
        if (!wrapper.style.aspectRatio) {
            wrapper.style.aspectRatio = `${ video.getAttribute('width') } / ${ video.getAttribute('height') }`;
            video.style.width = '100%';
            video.style.height = '100%';
        }
    });
}

// Pause any existing YouTube players
export function pauseYT() {
    document.querySelectorAll('.video-embed--youtube').forEach((embed) => {
        // Make sure YouTube object is available
        if ((typeof YT !== 'undefined') && (typeof YT.get !== 'undefined')) {
            const player = YT.get(embed.id);
            if (player && (typeof player.pauseVideo === 'function')) player.pauseVideo();
        }
    });
}

export function pauseVimeo() {
    document.querySelectorAll('.video-embed--vimeo').forEach((embed) => {
        const player = new Vimeo(embed);
        player.pause();
    });
}

export function pauseAllAutoplayVideos() {
    pauseYT();
    pauseVimeo();

    const autoplayVideos = document.querySelectorAll('video[autoplay]');
    autoplayVideos.forEach((video) => {
        if (!video.paused) {
            pauseHandler(video);
        }
    })
}

export function playAllAutoplayVideos() {
    const autoplayVideos = document.querySelectorAll('video[autoplay]');
    autoplayVideos.forEach((video) => {
        if (video.paused) {
            playHandler(video);
        }
    })

    // YouTube
    document.querySelectorAll('.video-embed--youtube').forEach((embed) => {
        if (embed.dataset.autoplay === '1') {
            // Make sure YouTube object is available
            if ((typeof YT !== 'undefined') && (typeof YT.get !== 'undefined')) {
                const player = YT.get(embed.id);
                if (player && (typeof player.playVideo === 'function')) player.playVideo();
            }
        }
    });

    // Vimeo
    document.querySelectorAll('.video-embed--vimeo').forEach((embed) => {
        if (embed.dataset.autoplay === '1') {
            let options = {
                id: embed.dataset.url,
                autoplay: embed.dataset.autoplay === '1',
                loop: embed.dataset.loop === '1',
                muted: embed.dataset.muted === '1',
            }

            const player = new Vimeo(embed, options);
            player.play();
        }
    });
}

// Stop playing other youtube videos if they're already playing, per youtube emailing us
function onStateChange(e) {
    // Only run on the current, now playing player to prevent conflicts
    const currentPlayer = e.target;
    if ((typeof currentPlayer.getPlayerState === 'function') && (currentPlayer.getPlayerState() === YT.PlayerState.PLAYING)) {
        document.querySelectorAll('.video-embed--youtube').forEach((embed) => {
            // Make sure YouTube object is available
            if ((typeof YT !== 'undefined') && (typeof YT.get !== 'undefined')) {
                const player = YT.get(embed.id);
                // Pause any non current videos
                if (player && (player !== currentPlayer) && (typeof player.pauseVideo === 'function')) {
                    player.pauseVideo();
                }
            }
        });
    }
}

// On ready event fires when a YouTube embed is fully loaded
function onReady(e) {
    const wrapper = e.target.getIframe().parentElement;
    // A short delay here can hide a blink from black background to pre-playing video thumbnail
    // 1000ms seems to be the minimum (I'm sure this is variable) to consistently hide the initial spinner on decent internet
    setTimeout(() => {
        wrapper.classList.add('active');
        // On another delay, fade out the black background since it won't align perfectly
        setTimeout(() => {
            wrapper.classList.add('no-background');
        }, window.transitionDuration);
    }, 1000);

    e.target.playVideo();
}

// Handle initializing youtube player, making SURE we need to
function initializeYTPlayer(embed) {
    // Check YT functions exist, and that either the player hasn't been loaded yet OR YT thinks it has but it actually got loaded out (e.g. through React)

    if ((typeof YT !== 'undefined') &&
        (typeof YT.get !== 'undefined') &&
        ((!!YT.get(embed.id) === false) || !document.querySelector(`#${ embed.id } iframe`))) {
        const player = new YT.Player(embed, {
            height: embed.dataset.height,
            width: embed.dataset.width,
            videoId: embed.dataset.id,
            origin: window.location.origin,
            playerVars: {
                autoplay: embed.dataset.autoplay,
                loop: embed.dataset.loop,
                mute: embed.dataset.muted,
                playlist: embed.dataset.loop === '1' ? embed.dataset.id : null, // Looping on YT requires setting the playlist to the current video id
                rel: 0,
            },
            events: {
                onStateChange,
                onReady,
            },
        });
    }


    resizeVideos();
}

function setupVideoEmbed(wrapper) {
    const embed = wrapper.querySelector('.video-embed');
    if (embed.classList.contains('video-embed--youtube')) {
        setupYT(wrapper);
    } else if (embed.classList.contains('video-embed--vimeo')) {
        setupVimeo(wrapper);
    }
}

// Setup all youtube iframes
// Something about this can throw errors in the console, but the embeds seem to look fine and this is how youtube lays out loading them so ¯\_(ツ)_/¯
function setupYT(wrapper) {
    // Load the script if it's not already present
    const script = document.querySelector('script[src^="https://www.youtube.com/iframe_api"]');
    if (!script) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentElement.insertBefore(tag, firstScriptTag);
    } else {
        // If the script has already loaded and the callback already fired, initialize the non-initialized player
        initializeYTPlayer(wrapper.querySelector('.video-embed--youtube'));
    }

    // Listen for the iframes to be ready and attached listeners. Has to be in the global namespace, and will only fire the first time the script is loaded
    window.onYouTubeIframeAPIReady = function () {
        initializeYTPlayer(wrapper.querySelector('.video-embed--youtube'));
    };
}

function setupVimeo(wrapper) {
    const embed = wrapper.querySelector('.video-embed--vimeo');
    let options = {
        id: embed.dataset.url,
        autoplay: embed.dataset.autoplay === '1',
        loop: embed.dataset.loop === '1',
        muted: embed.dataset.muted === '1',
    }

    const player = new Vimeo(embed, options);
    player.play();

    wrapper.classList.add('active');
    resizeVideos();
}

// Setup individual native HTML5 video
export function setupVideo(wrapper) {
    const video = wrapper.querySelector('video');
    if (!wrapper.dataset.initialized && video) {
        wrapper.dataset.initialized = 'true'; // Only initialize once...

        // Setup play/pause behavior
        const button = wrapper.querySelector('.video__play');
        if (button) {
            button.addEventListener('click', (e) => {
                // Prevent link behavior in case the video is inside a link tag
                e.preventDefault();
                // Make sure the video has loaded enough to be able to toggle the button
                if (video.readyState > 2) {
                    if (video.paused) {
                        playHandler(video);
                    } else {
                        pauseHandler(video);
                    }
                }
            });
        }

        // Either handle the autoplay starting or make a listener for when it's ready for that
        if (video.readyState > 2) {
            autoplayHandler(video);
        } else {
            video.addEventListener('canplay', autoplayHandler(video));
        }

        if (useAnalytics) {
            video.addEventListener('play', () => {
                // Only track a play once
                if (video.dataset.trackedPlay !== 'true') {
                    dataLayer.push({
                        event: 'video',
                        label: video.hasAttribute('autoplay') ? 'autoplay' : 'play',
                        value: video.dataset.label,
                    });

                    video.dataset.trackedPlay = 'true';
                }
            });
        }

        video.addEventListener('ended', () => {
            // When the video ends, we need to swap the to the "paused" state again
            pauseHandler(video);

            if (useAnalytics) {
                // Only track an end once
                if (video.dataset.trackedEnded !== 'true') {
                    dataLayer.push({
                        event: 'video',
                        label: 'ended',
                        value: video.dataset.label,
                    });

                    video.dataset.trackedEnded = 'true';
                }
            }
        });
    }
}

// Handle clicks on targetable elements of an embed wrapper
function handleEmbedClick(target, wrapper) {
    wrapper.classList.add('will-be-active');

    setupVideoEmbed(wrapper);

    if (useAnalytics) {
        dataLayer.push({
            event: 'video',
            label: 'play',
            value: wrapper.querySelector('.video-embed').dataset,
        });
    }
}

// Setup all video sizing and controls
export function setupVideos() {
    resizeVideos();

    // Setup native HTML5 players
    document.querySelectorAll('.video-wrapper').forEach((wrapper) => {
        setupVideo(wrapper);
    });

    // Setup embed players
    document.querySelectorAll('.video-embed-wrapper').forEach((wrapper) => {
        if (!wrapper.dataset.initialized) {
            wrapper.dataset.initialized = 'true'; // Only initialize once...
            // Determine if the video is supposed to autoplay...being respectful of prefers reduced motion
            const embed = wrapper.querySelector('.video-embed');
            if (embed) {
                const { autoplay } = embed.dataset;

                if (autoplay === '1' && !getReducedMotion()) {
                    wrapper.classList.add('will-be-active');
                    setupVideoEmbed(wrapper);
                } else {
                    // ...if it's not autoplay, setup listeners for when it gets clicked
                    wrapper.querySelectorAll('.video-embed__preview, .video-embed__play').forEach((target) => {
                        target.addEventListener('click', (e) => {
                            e.stopPropagation();
                            handleEmbedClick(target, wrapper);
                        });
                    });
                }
            }
        }
    });
}
