//=================================================
// On the Hour
//=================================================

export default function setupOnTheHour() {
    const THIRTY_SECONDS = 30000;
    const shouldDisable = document.querySelector('meta[name="on-the-hour"]')?.getAttribute('content') === "false";
    const wrapper = document.querySelector('.on-the-hour');
    const iframe = wrapper ? wrapper.querySelector('.on-the-hour__iframe') : null;
    const forceOnTheHour = document.location.search.match(/[?&]on-the-hour=(\w*)/);
    const url = wrapper?.dataset.url;

    function closeHandler(callback) {
        function closeCallback() {
            // Empty the iframe
            if (iframe) {
                iframe.src = '';
            }
            // Hide everything
            wrapper.classList?.remove('active');
            callback();
        }

        // Handle closing by the "X"
        wrapper.querySelector('.on-the-hour-legend__x')?.addEventListener('click', closeCallback, { once: true });

        // Let the project run for 30 seconds before closing
        setTimeout(closeCallback, THIRTY_SECONDS);
    }

    // Returns a Date object representing the top of the current hour
    function getTopOfCurrentHour(date = new Date()) {
        const topOfCurrentHour = new Date(date);
        topOfCurrentHour.setMinutes(0, 0, 0);
        return topOfCurrentHour;
    }

    // Checks if the current time is within the first minute of the hour
    function isWithinFirstMinute(date = new Date()) {
        return date.getMinutes() === 0 && date.getSeconds() < 60;
    }

    // Sets a session storage variable for tracking the on-the-hour event
    function setSessionVariable() {
        const currentDate = new Date();
        const storedTimestamp = sessionStorage.getItem('on-the-hour');
        const storedDate = storedTimestamp ? new Date(storedTimestamp) : null;

        // Only set a new session variable if the stored one is out of sync or doesn't exist
        if (!storedDate || isSessionVariableOutOfSync(storedDate, currentDate)) {
            sessionStorage.setItem('on-the-hour', currentDate.toISOString());
        }
    }

    // Calculate time difference in seconds between two dates
    function timeDistance(a, b) {
        const newA = a instanceof Date ? a.getTime() : a;
        const newB = b instanceof Date ? b.getTime() : b;
        return Math.abs(Math.floor(newA / 1000) - Math.floor(newB / 1000)); // Return difference in seconds
    }

    // Checks if the stored timestamp is out of sync with the current top of the hour
    function isSessionVariableOutOfSync(storedDate, currentDate) {
        const topOfCurrentHour = getTopOfCurrentHour(currentDate);
        return timeDistance(storedDate, topOfCurrentHour) > 60; // Returns true if out of sync
    }

    // Triggers the projects if conditions are met and schedules the next one
    function triggerOnTheHour(force = false) {
        if (shouldDisable) {
            return;
        }

        // Make sure elements are present
        if (wrapper && iframe) {
            const currentDate = new Date();
            const storedTimestamp = sessionStorage.getItem('on-the-hour');
            const storedDate = storedTimestamp ? new Date(storedTimestamp) : null;

            // Only trigger if forced, or if within the first minute of the hour and:
            // - No stored date exists OR
            // - The stored date exists but is not within the first minute of the current hour
            // ...the goal is to prevent multiple rapid re-fires on refresh or link clicks
            const shouldTrigger = force ||
                (isWithinFirstMinute(currentDate) && (!storedDate || !isWithinFirstMinute(storedDate))) ||
                (storedDate && isSessionVariableOutOfSync(storedDate, currentDate));

            if (shouldTrigger) {
                // Load and display the iframe content
                function loadCallback() {
                    wrapper.classList.add('active'); // Show the wrapper
                    closeHandler(scheduleNextCheck); // Setup the close handler and schedule the next check
                    setSessionVariable(); // Update session storage with the current time
                }

                iframe.addEventListener('load', loadCallback, { once: true });
                iframe.src = url;

                // Analytics tracking (if enabled)
                if (useAnalytics) {
                    dataLayer.push({
                        event: 'artport',
                        label: 'on_the_hour',
                        value: currentDate.getHours(),
                    });
                }
            } else {
                // If no trigger, still schedule the next check
                scheduleNextCheck();
            }
        } else {
            // If wrapper or iframe is not present, still schedule the next check
            scheduleNextCheck();
        }
    }

    // Schedules the next check based on the time until the next top of the hour
    function scheduleNextCheck() {
        const now = new Date();

        // Get the top of the current hour
        let nextTopOfHour = getTopOfCurrentHour(now);

        // If the current time is past the top of the current hour, move to the next hour
        if (now.getTime() >= nextTopOfHour.getTime()) {
            nextTopOfHour.setHours(nextTopOfHour.getHours() + 1);
        }

        // Calculate the exact time difference to the next top of the hour
        const timeUntilNextHour = nextTopOfHour.getTime() - now.getTime();

        // Set a timeout to trigger exactly at the next top of the hour
        setTimeout(() => {
            triggerOnTheHour();
        }, timeUntilNextHour);
    }

    // Expose this to the window so we can arbitrarily call it elsewhere (including signage for testing)
    window.forceOnTheHour = () => {
        // Remove existing session variable so it will for sure trigger
        sessionStorage.removeItem('on-the-hour');
        triggerOnTheHour(true)
    }

    // Check for query triggers
    if (forceOnTheHour) {
        window.forceOnTheHour();
    } else {
        // Only trigger if within the first minute of the top of the hour
        if (isWithinFirstMinute()) {
            triggerOnTheHour();
        } else {
            scheduleNextCheck(); // If not within the first minute, just schedule the next check
        }
    }
}
