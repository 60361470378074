//=================================================
// Lightbox
//=================================================

import { setupAudio } from './audio';
import { setupVideos } from './videos';
import { setupImageViewers } from './images';

// Update the next/prev and count controls in the lightbox
function updateOverlayControls(overlay) {
    if (!overlay) {
        return;
    }

    const count = overlay.querySelector('.overlay-header__count');
    const prev = overlay.querySelector('.overlay-header__prev');
    const next = overlay.querySelector('.overlay-header__next');

    const index = Number(overlay.dataset.index);
    const gallery = overlay.dataset.gallery;
    const galleryItems = document.querySelectorAll(`.overlay-preview-wrapper[data-gallery="${ gallery }"]`);

    if (galleryItems.length > 1) {
        [prev, next, count].forEach((el) => {
            if (el === null) {
                return;
            }

            el.classList.add('active');
        });

        if (prev) prev.disabled = index < 1
        if (next) next.disabled = Number(index) + 1 === galleryItems.length;
        if (count) count.innerText = `${ index + 1 } / ${ galleryItems.length }`;
    } else {
        [prev, next, count].forEach((el) => {
            if (el === null) {
                return;
            }

            el.classList.remove('active');
            el.disabled = true;
        });
    }
}

// Load the lightbox from a given wrapper element
function loadOverlayContent(wrapper) {
    const overlay = document.querySelector('.overlay--media');
    if (overlay) {
        const overlayContent = overlay.querySelector('.overlay__content');
        const content = wrapper.querySelector('.overlay-content');

        // Set data values for reference
        const galleryItems = document.querySelectorAll(`.overlay-preview-wrapper[data-gallery="${ wrapper.dataset.gallery }"]`);
        overlay.dataset.index = Array.prototype.indexOf.call(galleryItems, wrapper).toString();
        overlay.dataset.gallery = wrapper.dataset.gallery;

        // Clone content into the lightbox
        overlayContent.innerHTML = '';
        overlayContent.appendChild(content.cloneNode(true));

        // Need to reset any initializations, due to deep cloning for the lightbox
        overlayContent.querySelectorAll('*[data-initialized="true"]').forEach((el) => {
            el.removeAttribute('data-initialized');
        });

        setupAudio();
        setupVideos();
        updateOverlayControls(overlay);

        overlay.showModal();
        overlay.focus();

        setupImageViewers();
    }
}

export default function setupOverlays() {
    // Media lightboxes
    document.querySelectorAll('.overlay-preview-wrapper').forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...

            el.querySelector('.overlay-preview')?.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();

                loadOverlayContent(el);
            });
        }
    });

    // Generic lightboxes
    document.querySelectorAll('.overlay-wrapper').forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...

            const overlay = el.querySelector('.overlay');
            const toggle = el.querySelector('.overlay__toggle');

            toggle.addEventListener('click', (e) => {
                overlay.showModal();
            });
        }
    });

    // The actual overlay modals
    document.querySelectorAll('.overlay').forEach((overlay) => {
        if (overlay.dataset.initialized !== 'true') {
            overlay.dataset.initialized = 'true'; // Only initialize once...

            // Close overlay if click is outside of it or a close button
            overlay.addEventListener('click', (e) => {
                const rect = overlay.getBoundingClientRect();
                const insideOverlay = (rect.top <= e.clientY && e.clientY <= rect.top + rect.height &&
                    rect.left <= e.clientX && e.clientX <= rect.left + rect.width);

                if (!insideOverlay || e.target.matches('.overlay-close, .overlay-close *')) {
                    overlay.close();
                }
            });

            // Previous
            const prev = overlay.querySelector('.overlay-header__prev');
            if (prev) {
                prev.addEventListener('click', (e) => {
                    e.stopPropagation();
                    const galleryItems = document.querySelectorAll(`.overlay-preview-wrapper[data-gallery="${ overlay.dataset.gallery }"]`);
                    if (galleryItems) {
                        const prevItem = galleryItems[Number(overlay.dataset.index) - 1];
                        if (prevItem) {
                            loadOverlayContent(prevItem);
                        }
                    }
                })
            }

            // Next
            const next = overlay.querySelector('.overlay-header__next');
            if (next) {
                next.addEventListener('click', (e) => {
                    e.stopPropagation();
                    const galleryItems = document.querySelectorAll(`.overlay-preview-wrapper[data-gallery="${ overlay.dataset.gallery }"]`);
                    if (galleryItems) {
                        const nextItem = galleryItems[Number(overlay.dataset.index) + 1];
                        if (nextItem) {
                            loadOverlayContent(nextItem);
                        }
                    }
                });
            }

            // Handle keyboard controls
            // This can get messed up by OpenSeadragon viewers
            overlay.addEventListener('keydown', (e) => {
                if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
                    overlay.querySelector('.overlay-header__next')?.click();
                } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
                    overlay.querySelector('.overlay-header__prev')?.click();
                }
            });
        }
    });

}
